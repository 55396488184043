<template>
  <div class="wrapper">
    <header>
      <el-row class="head container">
        <el-col :xs="24" :sm="24" :md="16" :lg="16">
          <h1 class="logo">
            <a href="javascript:;" @click="goToHomePage">
              <img :src="require('@/assets/images/shuangzhaoDB-logo.png')" />
              <span class="logo-text">shuangzhaoDB</span>
            </a>
          </h1>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8">
          <ul class="nav">
            <li
              v-for="item in navList"
              :key="item.id"
              :class="{ activated: item.isActive }"
            >
              <a @click="$router.push(item.routerOpts)">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </el-col>
      </el-row>
    </header>
    <transition name="fade-transform" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
    <div class="footer" v-if="footer">
      <div class="contact">
        <div>联系电话：020-32225222(总机)</div>
        <div>联系邮箱：robot@shinetech-china.com</div>
      </div>
      <div class="copyright">
        <a href="http://www.shinetech-china.com/" target="_blank">
          广州市双照电子科技有限公司
        </a>
        |
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          粤ICP备14028460号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout",
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navList: [
        { id: 1, name: "首页", routerOpts: { name: "home" }, isActive: false },
        {
          id: 2,
          name: "产品",
          routerOpts: { name: "product" },
          isActive: false,
        },
        {
          id: 3,
          name: "申请试用",
          routerOpts: { name: "trial" },
          isActive: false,
        },
        {
          id: 4,
          name: "示例",
          routerOpts: { name: "demo" },
          isActive: false,
        },
      ],
    };
  },
  created() {
    this.matchActiveMenu();
  },
  updated() {
    this.matchActiveMenu();
  },
  methods: {
    matchActiveMenu() {
      this.navList.map((item) => {
        item.isActive = this.$router.history.current.path.includes(
          item.routerOpts.name
        );
      });
    },
    goToHomePage() {
      this.$router.push({ name: "home" });
      this.matchActiveMenu();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.wrapper {
  position: relative;
  padding-top: 60px;
  line-height: 1.8;
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    width: 100%;
    .head {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      h1.logo {
        display: flex;
        align-items: center;
        height: 60px;

        a {
          display: block;
          background-color: #fff;
          color: #fff;
          img {
            height: 30px;
            object-fit: cover;
            cursor: pointer;
          }
          .logo-text {
            font-size: 0;
          }
        }
      }
      @media screen and(max-width:991px) {
        h1.logo {
          justify-content: center;
        }
      }
      .nav {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        li {
          height: 60px;
          line-height: 60px;
          &.activated a {
            color: @themeRed;
            border-bottom: 3px solid @themeRed;
          }

          a {
            height: 100%;
            display: block;
            color: #092232;
            &:hover {
              color: @themeRed;
              border-bottom: 3px solid @themeRed;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #262a34;
    color: #fff;
    .copyright {
      padding: 0 15px;
      a:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
@media screen and(min-width:992px) {
  .copyright {
    line-height: 60px;
  }
}
@media screen and(max-width:991px) {
  .wrapper {
    padding-top: 120px;
    .footer {
      height: 100px;
      font-size: 14px;
    }
  }
}
</style>