import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'

Vue.use(VueRouter)
// 解决重复点击同一个路由时报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) { return originalPush.call(this, location).catch(err => err) }
const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/Home')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product',
      name: 'product',
      component: () => import('@/views/Product')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/design-concept',
      name: 'designConcept',
      component: () => import('@/views/DesignConcept')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/character',
      name: 'character',
      component: () => import('@/views/Character')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/application-scene',
      name: 'applicationScene',
      component: () => import('@/views/ApplicationScene')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/comparison',
      name: 'comparison',
      component: () => import('@/views/Comparison')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/architecture',
      name: 'architecture',
      component: () => import('@/views/Architecture')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'product/case',
      name: 'case',
      component: () => import('@/views/Case')
    }]
  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'trial',
      name: 'trial',
      component: () => import('@/views/Trial')
    }]

  }, {
    path: '/',
    component: Layout,
    children: [{
      path: 'demo',
      name: 'demo',
      component: () => import('@/views/Demo')
    }]
  }
]

export default new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
