import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/styles/index.less';
import '@/assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/display.css';

import { Row, Col, Button, Timeline, TimelineItem, Card, Tree, Divider, Form, FormItem, Input, Select, Option, Checkbox, Breadcrumb, BreadcrumbItem, Menu, MenuItem, Message, Image, Backtop, Icon } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Card)
Vue.use(Tree)
Vue.use(Divider)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Image)
Vue.use(Backtop)
Vue.use(Icon)
Vue.prototype.$message = Message

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
